var render = function() {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "menu", attrs: { "vi-if": "chef" } },
    [
      _c(
        "v-form",
        {
          ref: "form",
          attrs: { "lazy-validation": "" },
          model: {
            value: _vm.valid,
            callback: function($$v) {
              _vm.valid = $$v
            },
            expression: "valid"
          }
        },
        [
          _c(
            "v-container",
            [
              _c(
                "v-row",
                { attrs: { "align-content": "center" } },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "col-12 col-md-3",
                      on: {
                        click: function($event) {
                          return _vm.$router.push("/book")
                        }
                      }
                    },
                    [
                      _c(
                        "v-btn",
                        { attrs: { icon: "", rounded: "", href: "/book" } },
                        [
                          _c("v-icon", { attrs: { size: "36px" } }, [
                            _vm._v("mdi-chevron-left")
                          ])
                        ],
                        1
                      ),
                      _vm._v("Edit Booking")
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "col-12 col-md-6",
                      attrs: { "align-self": "center", align: "center" }
                    },
                    [_c("h2", [_vm._v("Please confirm the booking details")])]
                  ),
                  _c("v-col", { staticClass: "col-12 col-md-3" })
                ],
                1
              ),
              _c("v-row", [
                _c(
                  "div",
                  { staticClass: "col-xs-12 col-md-7" },
                  [
                    _c("v-card", [
                      _c(
                        "div",
                        {},
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "text-align": "center",
                                "font-size": "medium",
                                "font-weight": "700",
                                padding: "12px",
                                "border-radius": "4px",
                                "background-color": "#f7f3f3"
                              }
                            },
                            [
                              _vm._v(
                                " Your Booking with " +
                                  _vm._s(
                                    this.$root.chefSelected.profile.profileName
                                  )
                              )
                            ]
                          ),
                          _c(
                            "v-col",
                            [
                              _c("v-text-field", {
                                attrs: {
                                  "hide-details": "",
                                  dense: "",
                                  value:
                                    _vm.$store.state.user.userDetails
                                      .firstname +
                                    " " +
                                    _vm.$store.state.user.userDetails.lastname,
                                  label: "Name",
                                  "prepend-icon": "mdi-account",
                                  readonly: ""
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            [
                              _c("v-text-field", {
                                attrs: {
                                  readonly: "",
                                  dense: "",
                                  value:
                                    _vm.$store.state.user.userDetails.phone,
                                  label: "Phone",
                                  "prepend-icon": "mdi-phone",
                                  rules: [
                                    function(v) {
                                      return !!v || "Phone is required"
                                    }
                                  ]
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            [
                              _c("v-autocomplete", {
                                attrs: {
                                  "no-filter": true,
                                  "hide-details": "",
                                  readonly: "",
                                  items: _vm.$root.formData.locationItems,
                                  loading: _vm.isLoading,
                                  "search-input": _vm.lookupAddress,
                                  "item-text": "place_name",
                                  label: "Location",
                                  placeholder: "Start typing your address",
                                  "prepend-icon": "mdi-map-marker",
                                  "append-icon": "",
                                  "return-object": "",
                                  "auto-select-first": "",
                                  "hide-no-data": "",
                                  dense: "",
                                  rules: [
                                    function(v) {
                                      return !!v || "Location is required"
                                    },
                                    function(v) {
                                      return (
                                        this$1.checkRange()[0] ||
                                        "Chef out of range by " +
                                          this$1.checkRange()[1] +
                                          " Km"
                                      )
                                    }
                                  ]
                                },
                                on: {
                                  "update:searchInput": function($event) {
                                    _vm.lookupAddress = $event
                                  },
                                  "update:search-input": function($event) {
                                    _vm.lookupAddress = $event
                                  },
                                  change: _vm.getLocation
                                },
                                model: {
                                  value: _vm.$root.formData.location,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.$root.formData,
                                      "location",
                                      $$v
                                    )
                                  },
                                  expression: "$root.formData.location"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            [
                              _c("v-text-field", {
                                attrs: {
                                  "hide-details": "",
                                  dense: "",
                                  label: "Date",
                                  "prepend-icon": "mdi-calendar-range",
                                  readonly: "",
                                  rules: [
                                    function(v) {
                                      return !!v || "Date is required"
                                    }
                                  ]
                                },
                                model: {
                                  value: _vm.$root.formData.date,
                                  callback: function($$v) {
                                    _vm.$set(_vm.$root.formData, "date", $$v)
                                  },
                                  expression: "$root.formData.date"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            [
                              _c("v-text-field", {
                                attrs: {
                                  readonly: "",
                                  "hide-details": "",
                                  dense: "",
                                  "prepend-icon": "mdi-clock",
                                  items: _vm.availableTimes,
                                  label: "Time",
                                  rules: [
                                    function(v) {
                                      return !!v || "Time is required"
                                    }
                                  ]
                                },
                                model: {
                                  value: _vm.$root.formData.time,
                                  callback: function($$v) {
                                    _vm.$set(_vm.$root.formData, "time", $$v)
                                  },
                                  expression: "$root.formData.time"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            [
                              _c("v-text-field", {
                                attrs: {
                                  "hide-details": "",
                                  readonly: "",
                                  dense: "",
                                  "prepend-icon": "mdi-account-multiple",
                                  label: "Guests",
                                  type: "number",
                                  min:
                                    _vm.serviceSelectedCd.minGuests !==
                                    undefined
                                      ? _vm.serviceSelectedCd.minGuests
                                      : 2,
                                  max:
                                    _vm.serviceSelectedCd.maxGuests !==
                                    undefined
                                      ? _vm.serviceSelectedCd.maxGuests
                                      : 20,
                                  rules: [
                                    function(v) {
                                      return (
                                        !!v || "Number of guests is required"
                                      )
                                    },
                                    function(v) {
                                      return (
                                        _vm.serviceSelectedCd.minGuests ===
                                          undefined ||
                                        v >= _vm.serviceSelectedCd.minGuests ||
                                        "Minimum required is " +
                                          _vm.serviceSelectedCd.minGuests
                                      )
                                    }
                                  ]
                                },
                                model: {
                                  value: _vm.$root.nbGuests,
                                  callback: function($$v) {
                                    _vm.$set(_vm.$root, "nbGuests", _vm._n($$v))
                                  },
                                  expression: "$root.nbGuests"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            [
                              _c("v-textarea", {
                                attrs: {
                                  "hide-details": "",
                                  readonly: "",
                                  "prepend-icon": "mdi-comment",
                                  dense: "",
                                  label: "Comment",
                                  hint:
                                    "Tell the chef any allergy requirement or any additional information",
                                  placeholder:
                                    "Tell the chef any allergy requirement or any additional information",
                                  maxlength: 5000
                                },
                                model: {
                                  value: _vm.$root.comment,
                                  callback: function($$v) {
                                    _vm.$set(_vm.$root, "comment", $$v)
                                  },
                                  expression: "$root.comment"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]),
                    _c("v-divider", {
                      staticStyle: {
                        "margin-top": "12px",
                        "margin-bottom": "12px"
                      }
                    }),
                    _c(
                      "v-card",
                      [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "text-align": "center",
                              "font-size": "medium",
                              "font-weight": "700",
                              padding: "12px",
                              "border-radius": "4px",
                              "background-color": "#f7f3f3"
                            }
                          },
                          [_vm._v(" Location Details")]
                        ),
                        _c(
                          "v-col",
                          [
                            _c(
                              "div",
                              {
                                staticClass: "primary--text",
                                staticStyle: { "font-size": "12px" }
                              },
                              [
                                _vm._v(
                                  "What Equipments do you have in your kitchen? (Please select)"
                                )
                              ]
                            ),
                            _c(
                              "v-item-group",
                              {
                                attrs: { multiple: "" },
                                model: {
                                  value: _vm.equipmentsSelected,
                                  callback: function($$v) {
                                    _vm.equipmentsSelected = $$v
                                  },
                                  expression: "equipmentsSelected"
                                }
                              },
                              _vm._l(_vm.equipmentsKitchen, function(tag) {
                                return _c("v-item", {
                                  key: tag.displayName,
                                  attrs: { value: tag, color: "primary" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(ref) {
                                          var active = ref.active
                                          var toggle = ref.toggle
                                          return [
                                            _c(
                                              "v-chip",
                                              {
                                                attrs: {
                                                  "active-class":
                                                    "primary--text",
                                                  "input-value": _vm.checkChip(
                                                    _vm.equipmentsSelected,
                                                    tag
                                                  ),
                                                  filter: ""
                                                },
                                                on: { click: toggle }
                                              },
                                              [
                                                !_vm.checkChip(
                                                  _vm.equipmentsSelected,
                                                  tag
                                                )
                                                  ? _c(
                                                      "v-icon",
                                                      { attrs: { left: "" } },
                                                      [
                                                        _vm._v(
                                                          "mdi-checkbox-blank-outline"
                                                        )
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm._v(
                                                  " " +
                                                    _vm._s(tag.displayName) +
                                                    " "
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                })
                              }),
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          { staticClass: "container" },
                          [
                            _c(
                              "v-col",
                              {
                                staticStyle: {
                                  "align-self": "center",
                                  padding: "0px 12px 0px 12px"
                                },
                                attrs: { cols: "auto" }
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "primary--text",
                                    staticStyle: { "font-size": "12px" }
                                  },
                                  [
                                    _vm._v(
                                      "What Cooktop Type do you have? (Please select)"
                                    )
                                  ]
                                ),
                                _c(
                                  "v-input",
                                  {
                                    attrs: {
                                      rules: [
                                        function(v) {
                                          return (
                                            !!v || "This field is required!"
                                          )
                                        }
                                      ]
                                    },
                                    model: {
                                      value: _vm.cooktopType,
                                      callback: function($$v) {
                                        _vm.cooktopType = $$v
                                      },
                                      expression: "cooktopType"
                                    }
                                  },
                                  [
                                    _c(
                                      "v-item-group",
                                      {
                                        model: {
                                          value: _vm.cooktopType,
                                          callback: function($$v) {
                                            _vm.cooktopType = $$v
                                          },
                                          expression: "cooktopType"
                                        }
                                      },
                                      _vm._l(_vm.hobType, function(tag) {
                                        return _c("v-item", {
                                          key: tag.name,
                                          attrs: {
                                            value: tag,
                                            color: "primary"
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function(ref) {
                                                  var active = ref.active
                                                  var toggle = ref.toggle
                                                  return [
                                                    _c(
                                                      "v-chip",
                                                      {
                                                        attrs: {
                                                          "active-class":
                                                            "primary--text",
                                                          "input-value":
                                                            _vm.cooktopType !==
                                                              undefined &&
                                                            tag.name ===
                                                              _vm.cooktopType
                                                                .name,
                                                          filter: ""
                                                        },
                                                        on: { click: toggle }
                                                      },
                                                      [
                                                        _vm.cooktopType ===
                                                          undefined ||
                                                        tag.name !==
                                                          _vm.cooktopType.name
                                                          ? _c(
                                                              "v-icon",
                                                              {
                                                                attrs: {
                                                                  left: ""
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "mdi-checkbox-blank-circle-outline"
                                                                )
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              tag.displayName
                                                            ) +
                                                            " "
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            true
                                          )
                                        })
                                      }),
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          {
                            staticStyle: {
                              "align-self": "center",
                              padding: "0px 12px 0px 12px"
                            },
                            attrs: { cols: "auto" }
                          },
                          [
                            _c(
                              "v-radio-group",
                              {
                                attrs: {
                                  id: "location",
                                  color: "primary--text",
                                  row: "",
                                  label: "Is the event at your home ?",
                                  rules: [
                                    function(v) {
                                      return (
                                        v !== undefined ||
                                        "This field is required!"
                                      )
                                    }
                                  ]
                                },
                                model: {
                                  value: _vm.locationHome,
                                  callback: function($$v) {
                                    _vm.locationHome = $$v
                                  },
                                  expression: "locationHome"
                                }
                              },
                              [
                                _c("v-radio", {
                                  attrs: {
                                    id: "location_yes",
                                    label: "Yes",
                                    value: true
                                  }
                                }),
                                _c("v-radio", {
                                  attrs: {
                                    id: "location_no",
                                    label: "No",
                                    value: false
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _vm.locationHome !== undefined && !_vm.locationHome
                          ? _c(
                              "v-col",
                              {
                                staticStyle: {
                                  "align-self": "center",
                                  padding: "0px 12px 0px 12px"
                                },
                                attrs: { cols: "auto" }
                              },
                              [
                                _c("v-select", {
                                  attrs: {
                                    items: _vm.$store.state.locationType,
                                    "item-text": "displayName",
                                    "item-value": "name",
                                    attach: "",
                                    chips: "",
                                    label: "Location type",
                                    color: "#00695C",
                                    rules: [
                                      function(v) {
                                        return (
                                          (!!v && v.length !== 0) ||
                                          "You must select one location type!"
                                        )
                                      }
                                    ],
                                    required: "",
                                    "return-object": ""
                                  },
                                  model: {
                                    value: _vm.locationType,
                                    callback: function($$v) {
                                      _vm.locationType = $$v
                                    },
                                    expression: "locationType"
                                  }
                                })
                              ],
                              1
                            )
                          : _vm._e(),
                        Object.keys(_vm.getServiceCutlery.equipmentsService)
                          .length !== 0
                          ? _c(
                              "v-row",
                              { staticClass: "container" },
                              [
                                _c(
                                  "v-col",
                                  {
                                    staticStyle: {
                                      "align-self": "center",
                                      padding: "0px 12px 0px 12px"
                                    },
                                    attrs: { cols: "auto" }
                                  },
                                  [
                                    _c(
                                      "v-radio-group",
                                      {
                                        attrs: {
                                          id: "equipment",
                                          color: "primary--text",
                                          row: "",
                                          label:
                                            "Do you have the following equipments ?",
                                          rules: [
                                            function(v) {
                                              return (
                                                v !== undefined ||
                                                "This field is required!"
                                              )
                                            }
                                          ]
                                        },
                                        model: {
                                          value: _vm.equipmentRequired,
                                          callback: function($$v) {
                                            _vm.equipmentRequired = $$v
                                          },
                                          expression: "equipmentRequired"
                                        }
                                      },
                                      [
                                        _c("v-radio", {
                                          attrs: {
                                            id: "equipment_yes",
                                            label: "Yes",
                                            value: true
                                          }
                                        }),
                                        _c("v-radio", {
                                          attrs: {
                                            id: "equipment_no",
                                            label: "No",
                                            value: false
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _vm._l(
                                      _vm.getServiceCutlery.equipmentsService,
                                      function(value, keyService) {
                                        return _c(
                                          "ul",
                                          {
                                            key: keyService,
                                            staticClass: "margin-left-default"
                                          },
                                          [
                                            _vm.equipmentsService.find(function(
                                              e
                                            ) {
                                              return e.name === keyService
                                            }) !== undefined
                                              ? _c("li", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(value) +
                                                      " " +
                                                      _vm._s(
                                                        _vm.equipmentsService.find(
                                                          function(e) {
                                                            return (
                                                              e.name ===
                                                              keyService
                                                            )
                                                          }
                                                        ).displayName
                                                      ) +
                                                      " "
                                                  )
                                                ])
                                              : _vm._e()
                                          ]
                                        )
                                      }
                                    )
                                  ],
                                  2
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.serviceSelectedCd.requirements
                          ? _c(
                              "v-row",
                              { staticClass: "container" },
                              [
                                _c(
                                  "v-col",
                                  {
                                    staticStyle: {
                                      "align-self": "center",
                                      padding: "0px 12px 0px 12px"
                                    },
                                    attrs: { cols: "auto" }
                                  },
                                  [
                                    _c(
                                      "v-radio-group",
                                      {
                                        attrs: {
                                          id: "requirements",
                                          color: "primary--text",
                                          row: "",
                                          label:
                                            "Can you meet the following requirements",
                                          rules: [
                                            function(v) {
                                              return (
                                                v !== undefined ||
                                                "This field is required!"
                                              )
                                            }
                                          ]
                                        },
                                        model: {
                                          value: _vm.requirements,
                                          callback: function($$v) {
                                            _vm.requirements = $$v
                                          },
                                          expression: "requirements"
                                        }
                                      },
                                      [
                                        _c("v-radio", {
                                          attrs: {
                                            id: "requirements_yes",
                                            label: "Yes",
                                            value: true
                                          }
                                        }),
                                        _c("v-radio", {
                                          attrs: {
                                            id: "requirements_no",
                                            label: "No",
                                            value: false
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "margin-left-right-default margin-bottoms"
                                  },
                                  [
                                    _c("v-col", { attrs: { cols: "auto" } }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.serviceSelectedCd.requirements
                                          ) +
                                          " "
                                      )
                                    ])
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ],
                      1
                    ),
                    _c("v-divider", {
                      staticStyle: {
                        "margin-top": "12px",
                        "margin-bottom": "12px"
                      }
                    }),
                    _c(
                      "v-card",
                      [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "text-align": "center",
                              "font-size": "medium",
                              "font-weight": "700",
                              padding: "12px",
                              "border-radius": "4px",
                              "background-color": "#f7f3f3"
                            }
                          },
                          [_vm._v(" Service Description ")]
                        ),
                        _c("v-col", [
                          _c("div", [
                            _vm._v(
                              "The chef brings all ingredients, prepares the meal in your kitchen, serves each dish, and does the washing up before leaving"
                            )
                          ]),
                          _vm.getServiceCutlery.description !== undefined
                            ? _c("div", [
                                _vm._v(
                                  _vm._s(_vm.getServiceCutlery.description)
                                )
                              ])
                            : _vm._e(),
                          _vm.getServiceCutlery.warning !== undefined
                            ? _c("div", { staticClass: "warning--text" }, [
                                _vm._v(_vm._s(_vm.getServiceCutlery.warning))
                              ])
                            : _vm._e()
                        ])
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-xs-12 col-md-5" },
                  [
                    _c(
                      "v-card",
                      { attrs: { dark: "" } },
                      [
                        _c("v-container", [
                          _c("h2", [_vm._v("Price Details")])
                        ]),
                        _c(
                          "div",
                          { staticClass: "background-black-light" },
                          [
                            _vm.packageSelectedCd
                              ? _c(
                                  "div",
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "row",
                                        staticStyle: {
                                          width: "100%",
                                          margin: "0px"
                                        }
                                      },
                                      [
                                        _c("v-col", [
                                          _vm._v(
                                            _vm._s(_vm.packageSelectedCd.name) +
                                              " x " +
                                              _vm._s(_vm.$root.nbGuests)
                                          )
                                        ]),
                                        _vm.packageSelectedCd.price !==
                                          undefined &&
                                        _vm.$root.nbGuests !== undefined
                                          ? _c(
                                              "v-col",
                                              {
                                                staticClass: "col-auto",
                                                attrs: { "align-self": "end" }
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.formatPrice(
                                                        _vm.roundToXDigits(
                                                          _vm.packageSelectedCd
                                                            .price *
                                                            _vm.$root.nbGuests,
                                                          2
                                                        )
                                                      )
                                                    ) +
                                                    " "
                                                )
                                              ]
                                            )
                                          : _vm._e()
                                      ],
                                      1
                                    ),
                                    _vm._l(
                                      _vm.packageCategoriesSorted(
                                        _vm.packageSelectedCd
                                      ),
                                      function(packageCategory) {
                                        return _c(
                                          "div",
                                          { key: packageCategory.name },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "row",
                                                staticStyle: { margin: "0px" }
                                              },
                                              [
                                                _c("v-col", [
                                                  _vm._v(
                                                    _vm._s(
                                                      packageCategory
                                                        .itemCategory.name
                                                    )
                                                  )
                                                ]),
                                                _c("v-col", {
                                                  staticClass: "col-auto",
                                                  attrs: { "align-self": "end" }
                                                })
                                              ],
                                              1
                                            ),
                                            _vm._l(
                                              _vm.$root.dishesSelected[
                                                packageCategory.itemCategory
                                                  .name
                                              ],
                                              function(dish, index) {
                                                return _c(
                                                  "div",
                                                  {
                                                    key: index,
                                                    staticClass: "row",
                                                    staticStyle: {
                                                      "font-size": "14px",
                                                      width: "100%",
                                                      margin: "0px"
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "v-col",
                                                      {
                                                        staticStyle: {
                                                          "text-indent": "10px"
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            dish.description
                                                          ) + " "
                                                        )
                                                      ]
                                                    ),
                                                    _c("v-col", {
                                                      staticClass: "col-auto",
                                                      attrs: {
                                                        "align-self": "end"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              }
                                            )
                                          ],
                                          2
                                        )
                                      }
                                    )
                                  ],
                                  2
                                )
                              : _c(
                                  "div",
                                  _vm._l(
                                    _vm.serviceSelectedCd.itemCategories,
                                    function(category) {
                                      return _c("div", { key: category.name }, [
                                        _vm.$root.dishesSelected[
                                          category.name
                                        ] !== undefined &&
                                        _vm.totalCategory(category) > 0
                                          ? _c("div", [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "row",
                                                  staticStyle: {
                                                    width: "100%",
                                                    margin: "0px"
                                                  }
                                                },
                                                [
                                                  _c("v-col", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.categoryTitle(
                                                          category
                                                        )
                                                      )
                                                    )
                                                  ]),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass: "col-auto",
                                                      attrs: {
                                                        "align-self": "end"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.formatPrice(
                                                              _vm.totalCategory(
                                                                category
                                                              )
                                                            )
                                                          ) +
                                                          " "
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              ),
                                              category.multiSelect === true
                                                ? _c(
                                                    "div",
                                                    _vm._l(
                                                      _vm.$root.dishesSelected[
                                                        category.name
                                                      ],
                                                      function(dish, index) {
                                                        return _c(
                                                          "div",
                                                          {
                                                            key: index,
                                                            staticClass: "row",
                                                            staticStyle: {
                                                              "font-size":
                                                                "14px",
                                                              width: "100%",
                                                              margin: "0px"
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "v-col",
                                                              {
                                                                staticStyle: {
                                                                  "text-indent":
                                                                    "10px"
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.itemTitle(
                                                                      category,
                                                                      dish
                                                                    )
                                                                  )
                                                                )
                                                              ]
                                                            ),
                                                            _c(
                                                              "v-col",
                                                              {
                                                                staticClass:
                                                                  "col-auto",
                                                                attrs: {
                                                                  "align-self":
                                                                    "end"
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.formatPrice(
                                                                        _vm.totalItem(
                                                                          category,
                                                                          dish
                                                                        )
                                                                      )
                                                                    ) +
                                                                    " "
                                                                )
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      }
                                                    ),
                                                    0
                                                  )
                                                : _vm._e(),
                                              category.multiSelect !== true
                                                ? _c("div", [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass: "row",
                                                        staticStyle: {
                                                          "font-size": "14px",
                                                          width: "100%",
                                                          margin: "0px"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "v-col",
                                                          {
                                                            staticStyle: {
                                                              "text-indent":
                                                                "10px"
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.itemTitle(
                                                                  category,
                                                                  _vm.$root
                                                                    .dishesSelected[
                                                                    category
                                                                      .name
                                                                  ]
                                                                )
                                                              ) + " "
                                                            )
                                                          ]
                                                        ),
                                                        _c(
                                                          "v-col",
                                                          {
                                                            staticClass:
                                                              "col-auto",
                                                            attrs: {
                                                              "align-self":
                                                                "end"
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.formatPrice(
                                                                    _vm.totalItem(
                                                                      category,
                                                                      _vm.$root
                                                                        .dishesSelected[
                                                                        category
                                                                          .name
                                                                      ]
                                                                    )
                                                                  )
                                                                ) +
                                                                " "
                                                            )
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ])
                                                : _vm._e()
                                            ])
                                          : _vm._e()
                                      ])
                                    }
                                  ),
                                  0
                                ),
                            _vm.minChef ? _c("v-divider") : _vm._e(),
                            _vm.minChef
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "row",
                                    staticStyle: { margin: "0px" }
                                  },
                                  [
                                    _c("v-col", [
                                      _vm._v("Minimum order remaining")
                                    ]),
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "col-auto",
                                        attrs: { "align-self": "end" }
                                      },
                                      [_vm._v(" " + _vm._s(_vm.minChef) + " ")]
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.minChef
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "row",
                                    staticStyle: { margin: "0px" }
                                  },
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "caption",
                                        attrs: { align: "center" }
                                      },
                                      [
                                        _c("div", [
                                          _vm._v(
                                            "The chef requires a minimum order amount of " +
                                              _vm._s(
                                                _vm.formatPrice(
                                                  _vm.getPriceWithCommission(
                                                    _vm.serviceSelectedCd
                                                      .minPrice
                                                  )
                                                )
                                              )
                                          )
                                        ])
                                      ]
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.waiterPrice ? _c("v-divider") : _vm._e(),
                            _vm.waiterPrice
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "row",
                                    staticStyle: { margin: "0px" }
                                  },
                                  [
                                    _c("v-col", [_vm._v("Waiter")]),
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "col-auto",
                                        attrs: { "align-self": "end" }
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(_vm.waiterPrice) + " "
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.waiterPrice
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "row",
                                    staticStyle: { margin: "0px" }
                                  },
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "caption",
                                        attrs: { align: "center" }
                                      },
                                      [
                                        _c("div", [
                                          _vm._v(
                                            "The chef requires a wait person for this order"
                                          )
                                        ])
                                      ]
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.errorDishes ? _c("v-divider") : _vm._e(),
                            _vm.errorDishes
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "row",
                                    staticStyle: { margin: "0px" }
                                  },
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "error--text",
                                        attrs: { align: "center" }
                                      },
                                      [
                                        _c("div", [
                                          _vm._v("Select a Starter or a Desert")
                                        ])
                                      ]
                                    )
                                  ],
                                  1
                                )
                              : _vm._e()
                          ],
                          1
                        ),
                        _c("v-divider"),
                        _c(
                          "div",
                          {
                            staticClass: "row",
                            staticStyle: { margin: "0px", "font-size": "18px" }
                          },
                          [
                            _c("v-col", [_vm._v("Total")]),
                            _vm.total() !== undefined
                              ? _c(
                                  "v-col",
                                  {
                                    staticClass: "col-auto",
                                    attrs: {
                                      "align-self": "end",
                                      id: "booking_total"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.formatPrice(_vm.total())) +
                                        " "
                                    )
                                  ]
                                )
                              : _vm._e()
                          ],
                          1
                        ),
                        _c("v-divider"),
                        _c(
                          "div",
                          {
                            staticClass: "row",
                            staticStyle: { margin: "0px" }
                          },
                          [
                            _c(
                              "v-col",
                              { attrs: { align: "center" } },
                              [
                                _vm.isAvailableEvents()
                                  ? _c(
                                      "div",
                                      [
                                        _c("div", { staticClass: "caption" }, [
                                          _vm._v(
                                            " By clicking “Confirm and Pay” below, you agree to our "
                                          ),
                                          _c(
                                            "a",
                                            {
                                              attrs: {
                                                href: "/terms",
                                                target: "_blank"
                                              },
                                              on: {
                                                click: function($event) {
                                                  $event.stopPropagation()
                                                }
                                              }
                                            },
                                            [_vm._v("Terms and Conditions")]
                                          ),
                                          _vm._v(" and our "),
                                          _c(
                                            "a",
                                            {
                                              attrs: {
                                                href: "/privacy",
                                                target: "_blank"
                                              },
                                              on: {
                                                click: function($event) {
                                                  $event.stopPropagation()
                                                }
                                              }
                                            },
                                            [_vm._v("Privacy Policy")]
                                          ),
                                          _vm._v(". ")
                                        ]),
                                        _c("stripe-element-card", {
                                          ref: "elementRef",
                                          staticClass: "margin-default",
                                          attrs: { pk: _vm.stripeKey },
                                          on: { token: _vm.save }
                                        }),
                                        _c(
                                          "v-btn",
                                          {
                                            staticClass: "margin-default",
                                            attrs: {
                                              id: "btn_pay",
                                              outlined: "",
                                              color: "primary",
                                              disabled:
                                                !_vm.valid ||
                                                _vm.chef.profile.status.name !==
                                                  "APPROVED",
                                              loading: _vm.isLoadingPayment
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.startPaymentProcess()
                                              }
                                            }
                                          },
                                          [_vm._v("Confirm And Pay")]
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                !_vm.valid
                                  ? _c("div", { staticClass: "error--text " }, [
                                      _vm._v("some errors are present")
                                    ])
                                  : _vm._e(),
                                _vm.error
                                  ? _c("div", { staticClass: "error--text " }, [
                                      _vm._v(_vm._s(_vm.error))
                                    ])
                                  : _vm._e(),
                                _vm.chef.profile.status.name !== "APPROVED"
                                  ? _c("div", { staticClass: "error--text " }, [
                                      _vm._v(
                                        "This provider is not available for bookings"
                                      )
                                    ])
                                  : _vm._e(),
                                _vm.error
                                  ? _c(
                                      "v-btn",
                                      {
                                        staticClass: "margin-default",
                                        attrs: {
                                          outlined: "",
                                          color: "primary"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.$router.push("/book")
                                          }
                                        }
                                      },
                                      [_vm._v("Edit")]
                                    )
                                  : _vm._e(),
                                !_vm.isAvailableEvents()
                                  ? _c("div", [
                                      _vm._v(
                                        "This provider have No availability. Please send a date request"
                                      )
                                    ])
                                  : _vm._e(),
                                !_vm.isAvailableEvents()
                                  ? _c(
                                      "v-btn",
                                      {
                                        staticClass: "margin-default",
                                        attrs: {
                                          id: "btn_request",
                                          outlined: "",
                                          color: "primary",
                                          disabled:
                                            !_vm.valid ||
                                            _vm.chef.profile.status.name !==
                                              "APPROVED",
                                          loading: _vm.isLoadingPayment
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.save(undefined, true)
                                          }
                                        }
                                      },
                                      [_vm._v("Date Request")]
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }